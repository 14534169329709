import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';


// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"

import { unregister } from '../serviceWorker';
unregister();

let AsyncAppLayout = loadable({
  loader: () => import('components/Layout/AppLayout/'),
  loading: LoadingComponent
})
let AsyncException = loadable({
  loader: () => import('routes/exception/'),
  loading: LoadingComponent
})
let AsyncAccount = loadable({
  loader: () => import('routes/user/'),
  loading: LoadingComponent
})
let ApprovedMessage = loadable({
  loader: () => import('../routes/form/routes/forms/components/ApprovalMessage'),
  loading: LoadingComponent
})


class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      response: ""
    }
  }
  componentDidMount() {
    // try {
    //   caches.keys().then((names) => {
    //     names.forEach((name) => {
    //         caches.delete(name);
    //     });
    // });
    //   // caches.delete(true).then(res => {
    //   // })
    // }
    // catch (e) {
    // }
  }

  render() {
    const { match, location } = this.props;
    const isRoot = location.pathname === '/' ? true : false;
    if (isRoot) {
      return (<Redirect to={'/user/login'} />);
    }else if(location.pathname === '/user/schoolApproval'){
      return <ApprovedMessage/>
    }
    else {
      return (
        <div id="app">
          <Switch>
            <Route path={`${match.url}app`} component={AsyncAppLayout} />
            <Route path={`${match.url}user`} component={AsyncAccount} />
            <Route path="*" component={AsyncException} />
          </Switch>
        </div>
      );
    }
  }
}

export default App;
