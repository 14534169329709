import React from 'react';
import Loader from './Loader';

function Loading(props) {
  if (props.error) {
    window.location.reload(true);
  } else if (props.pastDelay) {
    return <div className="loader-container"> <Loader /> </div>;
  } else {
    return null; // Avoiding Flash Of Loading Component (<200ms)
  }
}

export default Loading;