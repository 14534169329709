export const GET_PROFILE_PENDING = 'GET_PROFILE_PENDING';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

export const  getProfilePending=()=> {
    
    return {
        type: GET_PROFILE_PENDING,
     
    }
}

export const  getProfileSuccess=(data)=> {
  
    return {
        type: GET_PROFILE_SUCCESS,
        payload: data
    }
}

export const  getProfileError=(error) =>{
    return {
        type: GET_PROFILE_ERROR,
        error: error
    }
}
