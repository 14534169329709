
import { GET_USER_DATA } from '../actions/authActions';

const data = JSON.parse(sessionStorage.getItem('userData'));

const initState = {
    userData: data !== null ? data : {},


}


export default function authReducer(state = initState, action) {

    switch (action.type) {

        case GET_USER_DATA:
            return {
                userData: action.payload
            }
        default:
            return state;
    }



}