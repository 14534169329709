import React from 'react';
import { render } from 'react-dom';
// import * as Sentry from "@sentry/react";
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import * as serviceWorker from './serviceWorker';
import { message } from 'antd';

// Sentry.init({
//   dsn: "https://c2f71ff1761d4b2a918da278671b59cc@o525114.ingest.sentry.io/5638649"
// });

const store = configureStore();

message.config({ maxCount: 1 });

function app() {
  let data = JSON.parse(sessionStorage.getItem("userData"));
  let title = "EasyScore for Faculty";
  try {
    if (data.primaryRoleId === 7 ) {
      title = "EasyScore for Faculty"
    }
    else if (data.primaryRoleId === 3) {
      title = "EasyScore School Admin"
    }
    else if (data.primaryRoleId === 4 ) {
      title = "EasyScore Quiz Author"
    }
    else if (data.primaryRoleId === 5 ) {
      title = "EasyScore Quiz Publisher"
    }
    else if (data.primaryRoleId === 6 ){
      title = "EasyScore Quiz Reviewer"
    }
    else {
      title = "EasyScore Admin"
    }
  }
  catch (e) {
    return title
  }
  return document.title = title

}

app();

render(<Root store={store} history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
