
import {GET_COURSE_DATA}from '../actions/courseFacultyActions';


const initState = {
   courseData: null,
   

}


export default function coursesReducer(state=initState, action){
    
   switch(action.type){
   
       case GET_COURSE_DATA:
         return {
             coursesData: {...action.payload}
         }
                
       default:
           return state;
   }



}


