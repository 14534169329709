
import {GET_SHEETS_LOADED, GET_SCHOOL_ID }from '../actions/onBoardActions';

 const sheetData= JSON.parse(localStorage.getItem('sheetData'));

const initState = {
   sheetData:  sheetData !== null ? sheetData: {},
   isShoolAdmin:false,
   isReelAdmin: false
}


export default function onBoardReducer(state=initState, action){
  
    switch(action.type){
     
        case GET_SHEETS_LOADED:
            localStorage.setItem('sheetData', JSON.stringify(action.payload));
            return {
                sheetData: action.payload,
                isReelAdmin: true,
                isSchoolAdmin: false
            }

        case GET_SCHOOL_ID:
            return {
                sheetData: action.payload.sheetData,
                isSchoolAdmin: action.payload.isSchoolAdmin,
                isReelAdmin: false
            }

        default:
            return state;
    }



}