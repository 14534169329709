import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import { connectRouter } from 'connected-react-router'
import onBoardReducer from './onBoardReducer';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import coursesReducer from './courseFacultyReducer';
const createRootReducer = (history) => combineReducers({
   settings: settingsReducer,
   router: connectRouter(history),
   onBoarding: onBoardReducer,
   authentication: authReducer,
   profile: profileReducer,
   courses: coursesReducer
});

export default createRootReducer;
