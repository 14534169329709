export const GET_COURSE_DATA = 'GET_COURSE_DATA';
export const GET_COURSE_DATA_BY_ID= 'GET_COURSE_DATA_BY_ID';

export const getCoursesData = data => {
  return {
    type: GET_COURSE_DATA,
    payload: data,
   
  };
};

export const getCoursesDataById = data => {
    return {
      type: GET_COURSE_DATA_BY_ID,
      payload: data,
    };
  };


