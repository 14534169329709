
import { GET_PROFILE_PENDING, GET_PROFILE_SUCCESS, GET_PROFILE_ERROR}from '../actions/profileActions';

 const data= JSON.parse(sessionStorage.getItem('profileData'));
const initialState = {
   pending: false,
   profileData: data,
   error: null

}

export default function profileReducer(state = initialState, action) {
    switch(action.type) {
        case GET_PROFILE_PENDING: 
            return {
                ...state,
                pending: true,
                error:null
            }
        case GET_PROFILE_SUCCESS:
        
              sessionStorage.setItem('profileData', JSON.stringify( action.payload ));
            return {
                ...state,
                pending: false,
                profileData: action.payload
            }
        case GET_PROFILE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                profileData: []
            }
        default: 
            return state;
    }
}

