// import createHistory from 'history/createHashHistory';
import { createBrowserHistory  } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers'
import thunk from 'redux-thunk';
// import logger from 'redux-logger'
//  import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

export const history = createBrowserHistory({
  hashType: 'slash',
  getUserConfirmation: (message, callback) => callback(window.confirm(message))
});


export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
      routerMiddleware(history),
      // logger, // for dispatching history actions
        // ... other middlewares ...
        thunk,
        // reduxImmutableStateInvariant()
      ),
    ),
  )

  return store
}