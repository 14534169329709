// action.js
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
export const GET_SHEETS_LOADED = 'GET_SHEETS_LOADED';
export const GET_SCHOOL_ID = 'GET_SCHOOL_ID';
export const ONBOARD_REELADMIN = 'ONBOARD_REELADMIN';

export const getSheetsLoaded = sheets => {
  return {
    type: GET_SHEETS_LOADED,
    payload: sheets,
  };
};

export const getSchoolId = id => {
  return {
    type: GET_SCHOOL_ID,
    payload: id,
  };
};